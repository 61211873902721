import React from 'react'

/**
 * Add a title-like heading
 *
 * @param {string} text
 */
const PriceComparison = () => {
  return <table class="table">
    <thead>
      <th>Farm / Shop</th>
      <th>Price per 7kg</th>
      <th>Price per kg</th>
    </thead>
    <tbody>
      <tr class="is-highlighted">
        <td>Kingswood Turkeys</td>
        <td>€63</td>
        <td>€9</td>
      </tr>
      <tr>
        <td>Dunnes Stores</td>
        <td>€79.99</td>
        <td>€11.43</td>
      </tr>
      <tr>
        <td>James Whelan</td>
        <td>€85.00</td>
        <td>€12.14</td>
      </tr>
      <tr>
        <td>FX Buckley</td>
        <td>€87.50</td>
        <td>€12.50</td>
      </tr>
      <tr>
        <td>Kerrigans</td>
        <td>€100.00</td>
        <td>€14.28</td>
      </tr>
      <tr>
        <td>Hogans Farm</td>
        <td>€104.00</td>
        <td>€14.85</td>
      </tr>
      <tr>
        <td>Lidl / Aldi</td>
        <td colspan="2">Not selling free range turkeys</td>
      </tr>
    </tbody>
  </table>
}

export default PriceComparison
