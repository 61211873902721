import React, { useState } from 'react'
import { add } from 'cart-localstorage'
import ProductsStyles from '../../styles/Products.module.sass'

/**
 * Display a section with Products
 *
 * @param {string} type
 * @param {string} class
 */
const Products = props => {
  function addToOrderAction() {
    // Generate ID
    const timestamp = + new Date()
    const randomNumbers = Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)
    const id = String(timestamp) + String(randomNumbers)

    // Generate Name
    const name = 'Turkey called ' + turkeyName + ' (' + turkeySizes[size].size + ' or ' + turkeySizes[size].pounds + ' pounds)'

    add({ id: id, name: name, price: turkeySizes[size].price }, 1)

    // Reload the page to update the floating cart
    // TODO: Research using Redux for this purpose in the future
    window.location.pathname = '/checkout'
  }

  const turkeySizes = [
    {
      "size": "7kg",
      "pounds": "15.5",
      "comparison": "About as heavy as a kids' bike",
      "price": 63,
      "image": "regular"
    },
    {
      "size": "8kg",
      "pounds": "17.5",
      "comparison": "Roughly the weight of 2 human newborns",
      "price": 72,
      "image": "large"
    },
    {
      "size": "9kg",
      "pounds": "20",
      "comparison": "As heavy as a Jeep tyre",
      "price": 81,
      "image": "xl"
    },
    {
      "size": "10kg+",
      "pounds": "22+",
      "comparison": "Roughly the weight of a car battery",
      "price": 90,
      "image": "xxl"
    }
  ]

  const [size, setSize] = useState(1)

  const generateName = (newSize) => {
    const processedSize = (newSize || newSize === 0) ? newSize : size

    const possibleNames = [
      "Aileen",
      "Boudica",
      "Colleen",
      "Deirdre",
      "Elizabeth",
      "Fionnuala",
      "Bronagh",
      "Caitriona",
      "Muireann",
      "Caoiffle",
      "Molly",
      "Bertha",
      "Madonna",
      "Marylin"
    ]

    const randomName = possibleNames[Math.floor(Math.random() * possibleNames.length)]

    if (processedSize === 3) {
      const xxlNames = [
        '-zilla',
        ' the Great',
        ' the Magnificent',
        ' the Giantess'
      ]

      return randomName + xxlNames[Math.floor(Math.random() * xxlNames.length)]
    }
    else if (processedSize === 2) {
      const xlNames = [
        ', Queen of Celts',
        ', Queen of Aran',
        ', Queen of Scots',
        ', Defeater of the British',
        ', Queen of Eire',
        ', Queen of the Emerald Isle'
      ]

      return randomName + xlNames[Math.floor(Math.random() * xlNames.length)]
    }
    else if (processedSize === 1) {
      return 'Princess ' + randomName
    }
    else if (processedSize === 0) {
      return 'Lady ' + randomName
    }
  }

  const changeSize = (type) => {
    if (type === 'increase') {
      if (size < turkeySizes.length - 1) {
        setSize(size + 1)

        setTurkeyName(generateName(size + 1))
      }
    }
    else if (type === 'decrease') {
      if (size > 0) {
        setSize(size - 1)

        setTurkeyName(generateName(size - 1))
      }
    }
  }

  const [turkeyName, setTurkeyName] = useState(generateName())

  return (
    <div className={`container is-wider-than-parent ${ProductsStyles.products || ''}`}>
      <div className={ProductsStyles.turkeySelector || ''}>
        <div className={ProductsStyles.header || ''}>
          <img className={ProductsStyles.image || ''} src={'/media/products/' + turkeySizes[size].image + '.png'} alt="kingswood turkey" />
          <strong className={ProductsStyles.smallTitle || ''}>Choose Size:</strong>
          <div>
            <span
              className={`button is-primary ${ProductsStyles.decrease || ''} ${(size === 0) && ProductsStyles.disabled}`}
              onClick={() => {
                changeSize('decrease')
              }}
              onKeyDown={() => {
                changeSize('decrease')
              }}
              role="button"
              tabIndex="0"
            >
              Smaller
            </span>
            <span
              className={`button is-primary ${ProductsStyles.increase || ''} ${(size === 3) && ProductsStyles.disabled}`}
              onClick={() => {
                changeSize('increase')
              }}
              onKeyDown={() => {
                changeSize('increase')
              }}
              role="button"
              tabIndex="0"
            >
              Bigger!!
            </span>
          </div>
        </div>
        <div className={ProductsStyles.info || ''}>
          <strong>{turkeySizes[size].size}</strong>
          <em>Or {turkeySizes[size].pounds} pounds</em>
          <p>{turkeySizes[size].comparison}</p>
          <div>
            <span className={ProductsStyles.price || ''}><small>&euro;</small><span>{turkeySizes[size].price}</span></span>
            <button
              className={`button is-primary is-large ${ProductsStyles.addToOrderButton ||
                ''}`}
              onClick={() => { addToOrderAction() }}
            >
              Add to Order!
            </button>
          </div>
        </div>
        <div className={ProductsStyles.additionalInfo || ''}>
          <p>Her name will be: <em>{turkeyName}</em> <button
            className={`button is-small ${ProductsStyles.generateName ||
              ''}`}
            onClick={() => { setTurkeyName(generateName()) }}
          >
            Change
          </button></p>
          <p>Collection date will be confirmed closer to Christmas.</p>
        </div>
      </div>
    </div>
  )
}

export default Products