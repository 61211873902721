import React from 'react'
import SiteMetadata from './SiteMetadata'
import FooterStyles from '../styles/Footer.module.sass'

/**
 * Display the global footer
 * 
 * @param {string} [ctaLink]
 * @param {boolean} [dimmed=false] - Reduce footer opacity
 */
const Footer = props => {
  const { title } = SiteMetadata()

  return (
    <footer className={`footer ${FooterStyles.footer || ''} ${props.dimmed === true ? FooterStyles.dimmed : ''}`}>
      <div className="container has-text-centered is-semi-narrow">
        <img src="/media/bird.svg" alt="turkey" />
        <p>
          Copyright{' '}
          <span className="current-year">{new Date().getFullYear()}</span>{' '}
          Kingswood Turkeys. All rights reserved. Raised in Dublin with <del>turkey feed</del> love.
          <br />
          Our turkeys pair well with most things but not particularly well with chocolate.
        </p>
      </div>
    </footer>
  )
}

export default Footer
