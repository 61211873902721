import React from 'react'
import { Link } from 'gatsby'
import SiteMetadata from './SiteMetadata'
import logo from '../../static/media/logo.svg'
import Phone from './shortcodes/Phone'
import NavbarStyles from '../styles/Navbar.module.sass'

/**
 * Display the global header
 */
const Navbar = props => {
  const { title } = SiteMetadata()

  return (
    <nav
      className={`navbar is-black is-transparent ${NavbarStyles.navbar || ''}`}
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container is-semi-narrow">
        <div className="navbar-brand">
          <Link
            to="/"
            className={`navbar-item ${NavbarStyles.logoHolder || ''}`}
            title="Logo"
          >
            <img
              src={logo}
              alt={title}
              className={`${NavbarStyles.logo || ''}`}
            />
          </Link>
        </div>

        <Phone class="button" />
      </div>
    </nav>
  )
}

export default Navbar
